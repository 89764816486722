<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                :disabled="true"
                v-model="code"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Title</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-textarea v-model="title" class="w-full" v-validate="'required'" />
              <span class="text-danger text-sm" v-show="errors.has('Title')">{{
                errors.first("Title")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedWarehouse"
                :options="optionWarehouse"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                track-by="code"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelWarehouse"
                @select="getWarehouseArea($event)"
              />
              <span class="text-danger text-sm" v-show="errors.has('Warehouse')">{{
                errors.first("Warehouse")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse Area</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedWarehouseArea"
                :options="optionWarehouseArea"
                :multiple="true"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                :limit="3"
                track-by="code"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelWarehouseArea"
              />
              <span class="text-danger text-sm" v-show="errors.has('WarehouseArea')">{{
                errors.first("WarehouseArea")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Request Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="date"
                type="date"
                v-validate="'required'"
              />
              <span class="text-danger text-sm" v-show="errors.has('Date')">{{
                errors.first("Date")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Method</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedMethod"
                :options="optionMethod"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
              />
              <span class="text-danger text-sm" v-show="errors.has('Method')">{{
                errors.first("Method")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Count Type</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedCountType"
                :options="optionCountType"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLabelCountType"
                @select="handleSelectItem()"
              />
              <span class="text-danger text-sm" v-show="errors.has('CountType')">{{
                errors.first("CountType")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-textarea v-model="note"  class="w-full" v-validate="'required'" />
              <span class="text-danger text-sm" v-show="errors.has('Note')">{{
                errors.first("Note")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <label
              >Attachment
              <small style="color: red"
                >(only: jpg, jpeg, pdf, doc, docx, png, xlsx, xls)</small
              ></label
            >
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
              <input
                id="fileInput"
                name="file"
                class="w-full inputx"
                type="file"
                ref="file"
                multiple="multiple"
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls"
              />
            </div>
          </div>
        </div>
        <div
          class="vx-row mb-3 mt-6 w-4/4"
          style="width: 100%; margin-left: 0%"
        >
          <div class="vx-col sm:w-1/3 w-full">
            <span></span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <div class="vx-col sm:w-4/5 w-full">
              <vs-button class="mr-3 mb-2" @click="handleAttachment"
                >Add Attachment</vs-button
              >
            </div>
          </div>
        </div>
        <!-- display: block -->
        <vs-divider style="width: 50%; margin-left: 10%">
          List Attachment
        </vs-divider>
        <div
          class="vx-row mb-3 mt-6 w-4/4"
          style="width: 50%; margin-left: 10%"
        >
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="handleShowAttachment(tr)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>

    <div v-if="selectedCountType.id == 1">
    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-col sm:w-5/5 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-3/12">
            <label class="vs-input--label">Warehouse Area</label>
          </div>
          <div class="vx-col w-3/12">
            <label class="vs-input--label">SKU</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Handling Unit</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Batch / Expired Date</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Qty</label>
          </div>
          <div class="vx-col w-1/12">

          </div>
        </div>
      </div>

      <div class="vx-row mb-2">
          <div class="vx-col w-3/12">
            <multiselect
            v-model="items[indextr].warehouseArea.selected"
            :options="optionWarehouseAreaItem"
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelWarehouseArea"
            :required="true"
            @select="getItemsSampling($event, indextr)"
            >
            </multiselect>
          </div>

          <div class="vx-col w-3/12">
            <multiselect
            v-model="items[indextr].sku.selected"
            :options="items[indextr].sku.options"
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelItemSKU"
            @search-change="handleSearchItemSKU"
            @select="getHandlingUnit($event, indextr)"
            :required="true"
            >
            </multiselect>
          </div>

          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].handlingUnit.selected"
            :options="items[indextr].handlingUnit.options"
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelHandlingUnit"
            :required="true"
            @select="getBatch($event, indextr)"
            >
            </multiselect>
          </div>

          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].batch.selected"
            :options="items[indextr].batch.options"
            :max-height="125"
            :show-labels="false"
            label="Name"
            :allow-empty="false"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelBatch"
            :required="true"
            @select="getQty($event, indextr)"
            >
            </multiselect>
            <datepicker v-if="isHide" name="expired_date" :inline="false" v-model="items[indextr].expiredDate" placeholder="Select Date"></datepicker>
          </div>

          <div class="vx-col w-1/12">
            <vs-input
              class="w-full"
              v-model="items[indextr].qty"
              :readonly="true"
              :disabled="true"
            />
          </div>

          <div class="vx-col w-1/12">
            <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
              <vs-button
                  v-if="items[indextr].status=='minus'"
                  style="display: table-cell;"
                  v-on:click="handleRemoveItem(indextr)"
                  color="danger"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-minus"
              ></vs-button>
              <vs-button
                  v-else
                  style="display: table-cell;"
                  v-on:click="handleAddItem()"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
              ></vs-button>
            </div>
          </div>
      </div>

      <vs-divider></vs-divider>
    </div>
    </div>

      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
        v-if="selectedCountType.id == 2"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item2 in table.limits"
                :key="item2"
                @click="handleChangelength(item2)"
              >
                <span>{{ item2 }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">

          <vs-th class="w-3/12 text-center" sort-key="warehouse_area">Warehouse Area</vs-th>
          <vs-th class="w-2/12 text-center" sort-key="sku_code">SKU Code</vs-th>
          <vs-th class="w-3/12 text-center" sort-key="item_name">SKU Description</vs-th>
          <vs-th class="w-1/12 text-center" sort-key="hu">HU</vs-th>
          <vs-th class="w-1/12 text-center" sort-key="qty">Qty</vs-th>
          <vs-th class="w-1/12 text-center" sort-key="bacth">Batch</vs-th>
          <vs-th class="w-1/12 text-center" sort-key="expired_date">ED</vs-th>
        </template>
        <template>
          <vs-tr :data="tr" :key="indexTr2" v-for="(tr, indexTr2) in table.data">
            <vs-td :data="tr.warehouse_area_name"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.warehouse_area_name"
                />
              </div>
            </vs-td>
            <vs-td :data="tr.sku_code">
              <div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.sku_code"
                /></div
            ></vs-td>
            <vs-td :data="tr.item_name"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.item_name"
                />
              </div>
            </vs-td>
            <vs-td :data="tr.unit"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.unit"
                />
              </div>
            </vs-td>
            <vs-td v-if="tr.amount" :data="tr.amount"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.amount"
                />
              </div>
            </vs-td>
            <vs-td v-else :data="tr.quantity"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.quantity"
                />
              </div>
            </vs-td>
            <vs-td :data="tr.batch"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.batch"
                />
              </div>
            </vs-td>
            <vs-td :data="tr.expired_date"
              ><div>
                <vs-input
                  class="w-full"
                  type="input"
                  :disabled="true"
                  v-model="tr.expired_date"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
        v-if="selectedCountType.id == 2"
      />
      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()" v-if="!editOrShow">
            Save
          </vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      code: null,
      title: null,
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      editOrShow: false,
      table: this.tableDefaultState(),
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      isHide: false,
      addTr: 3,
      closeTr: 3,
      file_attachments: 2,
      optionItemSKU: [],
      optionWarehouse: [],
      selectedWarehouse: null,
      optionWarehouseArea: [],
      selectedWarehouseArea: null,
      optionWarehouseAreaItem: [],
      optionCountType: [
        {
          name: "SAMPLING",
          id: 1,
        },
        {
          name: "FULL",
          id: 2,
        },
      ],
      selectedCountType: { name: "", id: 0 },
      optionMethod: ["Guided", "Unguided"],
      selectedMethod: "Guided",
      isFULL: false,
      note: null,
      itemSampling: [],
      items: [{
          status: "plus",
          sku:{
              selected: null,
              options: [],
          },
          handlingUnit: {
              selected: null,
              options: [],
          },
          warehouseArea: {
              selected: null,
              options: [],
          },
          qty: null,
          batch: {
              selected: null,
              options: [],
          },
          expiredDate: moment().format("YYYY-MM-DD"),
      }],
      datas: [
        {
          sku_code: "",
          name: "",
          sku_item: [
            {
              warehouse_area_name: "",
              unit: "",
              quantity: "",
            },
          ],
        },
      ],
      fileAttachment: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "sof" });
      this.$vs.loading.close();
    },

    handleCheckEditOrShow() {
      if (this.$route.query.action == "Show") {
        this.disableRejectAndAccept = true;
        this.disabledReason = true;
        this.editOrShow = true;
      }
    },
    customLabelWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelWarehouseArea({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelCountType({ name }) {
      return `${name}`;
    },
    customLabelItemSKU({ sku_code, item_name }) {
      return `${sku_code} - ${item_name}`;
    },
    customLabelHandlingUnit({ unit, name }){
      var huName = unit ? unit : name
      return `${huName}`;
    },
    customLabelBatch({ batch, expired_date }){
      var exDate = this.formatDate(expired_date);
      return `${batch} / ${exDate}`;
    },
    handleSelectItem() {
      if (this.selectedCountType.id == 2) {
        this.isFULL = true;
      }else{
        this.optionWarehouseAreaItem = this.selectedWarehouseArea
      }
      this.getItems();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getItems();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getItems();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getItems();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getItems();
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWarehouseArea(val) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouse-areas/type/"+val.id+"/Actual Storage", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouseArea = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItems() {
      if(this.selectedWarehouse && this.selectedWarehouseArea){
        let warehouseAreaID = [];
        for (let index = 0; index < this.selectedWarehouseArea.length; index++) {
          warehouseAreaID[index] = this.selectedWarehouseArea[index].id;
        }
        this.$vs.loading();
        this.$http
          .get("/api/wms/v1/stock-opname/allItemStock", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              warehouse_id: this.selectedWarehouse.id,
              warehouse_area_id: warehouseAreaID,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.table.data = [];
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.optionItemSKU = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    getItemsFull(id) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/stock-opname-line", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            stock_opname_id: id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.data = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.sku_item;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItemsSampling(val, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/sku-sampling", {
          params: {
            page: this.table.page,
            search: this.table.search,
            query: this.table.search,
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: val.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].sku.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getHandlingUnit(val, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/unit-sampling", {
          params: {
            page: this.table.page,
            search: this.table.search,
            query: this.table.search,
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: val.sku_code,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].handlingUnit.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getBatch(val, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/batch-sampling", {
          params: {
            page: this.table.page,
            search: this.table.search,
            query: this.table.search,
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: this.items[index].sku.selected.sku_code,
            unit: val.unit,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].batch.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getQty(val, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/qty-sampling", {
          params: {
            page: this.table.page,
            search: this.table.search,
            query: this.table.search,
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: this.items[index].sku.selected.sku_code,
            unit: this.items[index].handlingUnit.selected.unit,
            batch: val.batch,
            expired_date: val.expired_date,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].qty = resp.data.records[0].amount;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.code = resp.data.stockopname.Code;
            this.title = resp.data.stockopname.Title;
            this.date = this.formatInputDate(resp.data.stockopname.RequestDate);
            this.selectedMethod = resp.data.stockopname.Method;
            this.selectedCountType.name = resp.data.stockopname.CountType;
            if (resp.data.stockopname.CountType == 'FULL'){
              this.selectedCountType.id = 2
            }else{
              this.selectedCountType.id = 1
            }
            this.note = resp.data.stockopname.Note;

            if (resp.data.stockopname.WarehouseID) {
                this.setWarehouseSelected(resp.data.stockopname.WarehouseID);
                this.getWarehouseArea(resp.data.stockopname.WarehouseID);
            }
            if (resp.data.stockopname_storage.length > 0) {
              this.setWarehouseAreaSelected(resp.data.stockopname_storage);
            }

            if (resp.data.stockopname.CountType == 'FULL'){
              this.getItemsFull(resp.data.stockopname.id);
            }else{
              this.items = [];
              var statusAdd;
              for(var k = 0; k < resp.data.stockopname_line.length; k++){
                if(k == 0){
                    statusAdd = "plus"
                }else{
                    statusAdd = "minus"
                }

                _this.items.push({
                    status: statusAdd,
                    sku:{
                        selected: null,
                        options: [],
                    },
                    handlingUnit: {
                        selected: null,
                        options: [],
                    },
                    warehouseArea: {
                        selected: null,
                        options: [],
                    },
                    reason: {
                        selected: null,
                        options: [],
                    },
                    qty: resp.data.scrap_line[k].Quantity,
                    batch: {
                        selected: null,
                        options: [],
                    },
                    expiredDate: resp.data.scrap_line[k].ItemExpiredDate,
                })

                if (resp.data.scrap_line[k].ItemID) {
                  this.setSkuSelected(k, resp.data.scrap_line[k].ItemID);
                }
                if (resp.data.scrap_line[k].UnitID) {
                  this.setUnitSelected(k, resp.data.scrap_line[k].UnitID);
                }
                if (resp.data.scrap_line[k].WarehouseAreaID) {
                  //console.log("resp.data.scrap_line[k].WarehouseAreaID", resp.data.scrap_line[k].WarehouseAreaID)
                  this.setWarehouseAreaSelected(k, resp.data.scrap_line[k].WarehouseAreaID);
                  this.getItemsEdit(resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, k);
                  this.getHandlingUnitEdit(resp.data.scrap_line[k].ItemUnitID, resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, k);

                }
                if (resp.data.scrap_line[k].ReasonID) {
                  this.setReasonSelected(k, resp.data.scrap_line[k].ReasonID);
                }

              }
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setWarehouseSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/warehouse/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedWarehouse = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setWarehouseAreaSelected(val){
      var warehouseAreaIds = []
      val.forEach((el) => {
          this.$vs.loading();
          this.$http.get("/api/wms/v1/master/warehouse-area/" + el.WarehouseAreaID).then((resp) => {
            if (resp.code == 200) {
              warehouseAreaIds.push(resp.data);
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      });
      this.selectedWarehouseArea = warehouseAreaIds
    },
    handleSearchItemSKU(searching) {
      this.table.search = searching;
      this.table.page = 0;
    },
    handleAddItem() {
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          sku:{
            selected: null,
            options: [],
          },
          handlingUnit: {
            selected: null,
            options: [],
          },
          warehouseArea: {
            selected: null,
            options: [],
          },
          qty: null,
          batch:{
              selected: null,
              options: [],
          },
          expiredDate: moment().format("YYYY-MM-DD"),
      })
    },
    handleRemoveItem(index) {
      this.items.splice(index, 1);
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log("this.file[i]", this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        if (this.title == null) {
          this.errors.add({
            field: "Title",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedWarehouse == null) {
          this.errors.add({
            field: "Warehouse",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedWarehouseArea == null) {
          this.errors.add({
            field: "WarehouseArea",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedCountType.id == 0) {
          this.errors.add({
            field: "CountType",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.note == null) {
          this.errors.add({
            field: "Note",
            msg: "This field is required",
          });
          result = false;
        }

        if (result) {
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });

    },
    postData() {
      let form = new FormData();
      form.append("title", this.title);
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", this.date);
      form.append("method", this.selectedMethod);
      form.append("count_type", this.selectedCountType.name);
      form.append("note", this.note);

      for (let index = 0; index < this.selectedWarehouseArea.length; index++) {
        form.append("warehouse_area_id[]", this.selectedWarehouseArea[index].id);
      }
      for (let index = 0; index < this.items.length; index++) {
        form.append("sku_code[]", this.items[index].sku.selected ? this.items[index].sku.selected.sku_code : null);
        form.append("unit[]", this.items[index].handlingUnit.selected ? this.items[index].handlingUnit.selected.unit : null);
        form.append("batch[]", this.items[index].batch.selected ? this.items[index].batch.selected.batch : null);
        form.append("expired_date[]", this.items[index].batch.selected ? this.items[index].batch.selected.expired_date : null);
      }
      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/save", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Stock Opname has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.handleClose();
          this.$vs.loading.close();
        });
    },
    putData() {
      let form = new FormData();
      form.append("title", this.title);
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", this.date);
      form.append("method", this.selectedMethod);
      form.append("count_type", this.selectedCountType.name);
      form.append("note", this.note);

      for (let index = 0; index < this.selectedWarehouseArea.length; index++) {
        form.append("warehouse_area_id[]", this.selectedWarehouseArea[index].id);
      }
      for (let index = 0; index < this.items.length; index++) {
        form.append("sku_code[]", this.items[index].sku.selected ? this.items[index].sku.selected.sku_code : null);
        form.append("unit[]", this.items[index].handlingUnit.selected ? this.items[index].handlingUnit.selected.unit : null);
        form.append("batch[]", this.items[index].batch.selected ? this.items[index].batch.selected.batch : null);
        form.append("expired_date[]", this.items[index].batch.selected ? this.items[index].batch.selected.expired_date : null);
      }
      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-opname/update/" + this.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Stock Opname has been updated",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.handleClose();
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
  mounted() {
    this.handleCheckEditOrShow();
    this.getWarehouse();
    if (this.id) {
      this.getData();
    }
  },
  computed: {
    formatDate: () => {
        return (val) => {
            const momentDate = moment.utc(val);
            return momentDate.format('DD MMM YYYY');
        };
    },
    formatInputDate: () => {
        return (val) => {
            return moment.utc(val).format('YYYY-MM-DD');
        };
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
